import { useEffect, useState } from 'react'
import { ListBox, OwnerInformationModal } from '../../components'
import { titleCase } from '@bigbroindia/helper'
import { getColorByStatus } from '../../helpers/shorthand'
import { useHouseStore, useUserStore } from '../../stores'
import { useNavigate } from 'react-router-dom'
import { NDataOptionModal } from '../../monorep'

const HouseList = () => {

    const navigate = useNavigate()

    const userStore = useUserStore()
    const houseStore = useHouseStore()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [showOptionModal, setShowOptionModal] = useState<boolean>(false)
    const [showOwnerInformationModal, setShowOwnerInformationModal] = useState<boolean>(false)

    const handleRowClick = async (id: string) => {
        houseStore.getHouse(id, () => {
            setShowOptionModal(true)
        })
    }

    useEffect(() => {
        const timeout = setTimeout(() => houseStore.getHouses())
        return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ListBox
          name={'Houses'}
          setCurrentPage={setCurrentPage}
          columns={{ name: 'Name', furnishing: 'Furnishing Type', views: 'Views', wishlists: 'Wishlisted By', city: 'City', for: 'Rent/Sale' ,status: 'Status' }}
          data={houseStore.houses}
          meta={houseStore.meta}
          primary={['id']}
          handleRefreshButton={() => houseStore.getHouses()}
          onRowClick={(data: any) => handleRowClick(data.id)}
          handleAddButton={() => navigate('/property/houses/add')}
        >
            {showOptionModal && <NDataOptionModal
              visible={showOptionModal}
              setVisible={setShowOptionModal}
              primary={houseStore.house.id}
              options={
                [
                    [
                        { value: titleCase(houseStore.house.propertyStatus), color: getColorByStatus(houseStore.house.propertyStatus), disabled: true }
                    ],
                    [
                        { title: houseStore.house.propertyStatus === 'in-review' ? 'Review' : 'Update', color: 'info', icon: 'fa-pencil', onClick: (id: string) => houseStore.house.propertyStatus === 'in-review' ? navigate('/property/houses/:id/review'.replace(':id', id)) : navigate('/property/houses/:id/update'.replace(':id', id)) },
                        {
                            title: 'View Owner Information', color: 'warning', icon: 'fa-eye', onClick: (id: string) => {
                                userStore.getUser(houseStore.house.ownerId, () => {
                                    setShowOwnerInformationModal(true)
                                    setShowOptionModal(false)
                                })
                            }
                        }
                    ]
                ]
              }
            />}
            {showOwnerInformationModal && <OwnerInformationModal
                showModal={showOwnerInformationModal}
                setShowModal={setShowOwnerInformationModal}
                user={userStore.user}
                setShowOptionModal={setShowOptionModal}
            />}
        </ListBox>
    )
}

export default HouseList