import { useEffect, useState } from 'react'
import { useFlatStore, useUserStore } from '../../stores'
import { ListBox, OwnerInformationModal } from '../../components'
import { titleCase } from '@bigbroindia/helper'
import { getColorByStatus } from '../../helpers/shorthand'
import { useNavigate } from 'react-router-dom'
import { NDataOptionModal } from '../../monorep'

const FlatList = () => {

    const flatStore = useFlatStore()
    const userStore = useUserStore()
    const navigate = useNavigate()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [showOptionModal, setShowOptionModal] = useState<boolean>(false)
    const [showOwnerInformationModal, setShowOwnerInformationModal] = useState<boolean>(false)

    const handleRowClick = async (id: string) => {
        flatStore.getFlat(id, () => {
            setShowOptionModal(true)
        })
    }

    // const handleRefreshButton = () => flatStore.getFlats()

    useEffect(() => {
        const timeout = setTimeout(() => flatStore.getFlats())
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ListBox
            name={'Flats'}
            setCurrentPage={setCurrentPage}
            columns={{ name: 'Name', bhk: 'Flat Type', furnishing: 'Furnishing Type', views: 'Views', wishlists: 'Wishlisted By', city: 'City', for: 'Rent/Sale', status: 'Status' }}
            data={flatStore.flats}
            meta={flatStore.meta}
            primary={['id']}
            handleRefreshButton={() => flatStore.getFlats()}
            handleAddButton={() => navigate('/property/flats/add')}
            onRowClick={(data: any) => handleRowClick(data.id)}
        >
            {showOptionModal && <NDataOptionModal
                visible={showOptionModal}
                setVisible={setShowOptionModal}
                primary={flatStore.flat.id}
                options={
                    [
                        [
                            { value: titleCase(flatStore.flat.propertyStatus), color: getColorByStatus(flatStore.flat.propertyStatus), disabled: true }
                        ],
                        [
                            { title: flatStore.flat.propertyStatus === 'in-review' ? 'Review' : 'Update', color: 'info', icon: 'fa-pencil', onClick: (id: string) => flatStore.flat.propertyStatus === 'in-review' ? navigate('/property/flats/:id/review'.replace(':id', id)) : navigate('/property/flats/:id/update'.replace(':id', id)) },
                            {
                                title: 'View Owner Information', color: 'warning', icon: 'fa-eye', onClick: (id: string) => {
                                    userStore.getUser(flatStore.flat.ownerId, () => {
                                        setShowOwnerInformationModal(true)
                                        setShowOptionModal(false)
                                    })
                                }
                            }
                        ]
                    ]
                }
            />}
            {showOwnerInformationModal && <OwnerInformationModal
                showModal={showOwnerInformationModal}
                setShowModal={setShowOwnerInformationModal}
                user={userStore.user}
                setShowOptionModal={setShowOptionModal}
            />}
        </ListBox>
    )
}

export default FlatList