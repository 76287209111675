import { create } from 'zustand'
import { UserAPIRequestFilter, UserAPIResponse, UserFindAPIResponse } from "../models/user/model"
import { userRepository } from '../models/user/repository'

interface UserStateInterface {
    loading: boolean
    error: null | string
    success: null | string
    user: UserAPIResponse
    owner: UserFindAPIResponse
}

interface UserActionInterface {
    setLoading: (loading: boolean) => void
    setError: (value: string | null) => void
    resetStore: () => void
    resetAlert: () => void
    findUser: (id: string, callback?: () => void) => void
    getUser: (id: string, callback?: () => void) => void
}

const initial: UserStateInterface = {
    loading: false as boolean,
    error: null,
    success: null,
    owner: {} as UserFindAPIResponse,
    user: {} as UserAPIResponse
}

const useUserStore = create<UserStateInterface & UserActionInterface>((set) => ({
    ...initial,
    setLoading: (loading: boolean) => set(() => ({ loading: loading })),
    setError: (value: string | null) => set(() => ({ error: value })),
    resetStore: () => set(() => (initial)),
    resetAlert: () => set(() => ({ success: null, error: null })),

    findUser: async (id: string, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await userRepository.findUser(id)
        if (response.data) {
            set({ owner: response.data })
            if (callback) callback ()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    getUser: async (id: string, callback?: () => void) => {
        set({ loading: false, error: null })
        const response = await userRepository.getUser(id, {} as UserAPIRequestFilter)
        if (response.data) {
            set({ user: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    }
}))

export default useUserStore