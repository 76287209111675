import './index.css'

import { Image, Spinner } from '@nanobits/react-ui'
import React, { useEffect, useRef, useState } from 'react'

import { Camera } from './webcam'
import { Cropper } from './crop'
import Icon from '@nanobits/react-fa-icons'
import { ImageUploadTypeProp } from './type'
import styled from 'styled-components'

const Div = styled.div<{width?: number}>`
    ${({width}) => width && `width: ${width}px;`}
`
//const Hr = styled.hr``;
const Input = styled.input`display: none;`

export const ImageUpload = ({
    thumb,
    height,
    width,
    onUploadSelect,
    clearPreview,
    camOff,
    aspects
}:ImageUploadTypeProp) => {
    const previewThumb = thumb || `https://dummyimage.com/${width}x${height}/e6dfe6/7f84c7`
    const [preview, setPreview] = useState<string|null|any>()
    const [showCameraModel, setShowCameraModel] = useState(false)
    const [showCropperModel, setShowCropperModel] = useState(false)
    const [loading, setLoading] = useState(false)
    const [toCrop, setToCrop] = useState<string>()
    const imageRef = useRef<HTMLInputElement | null>(null)
    const openOsExplorer = () =>{
        setLoading(true)
        imageRef.current?.click()
        setLoading(false)
    }

    const convertBase64ToBlob = (base64Image: string) => {
        const parts = base64Image.split(';base64,')
        const imageType = parts[0].split(':')[1]
        const decodedData = window.atob(parts[1])
        const uInt8Array = new Uint8Array(decodedData.length)
        for (let i = 0; i < decodedData.length; ++i) {
          uInt8Array[i] = decodedData.charCodeAt(i)
        }
        return new Blob([uInt8Array], { type: imageType })
      }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.currentTarget.files as any
        const reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onloadend = function (e) {
            if(reader.result && (typeof reader.result === 'string')){
                setToCrop(reader.result)
                setShowCropperModel(true)
            }
        }
    }

    const handleCroppedChange = (image: string) => {
        setPreview(image)
        if(onUploadSelect){
            const BlobData = convertBase64ToBlob(image) as Blob
            const cam = [] as any
            if(BlobData){
                cam.push(BlobData)
            }
            return onUploadSelect(cam)
        }
        throw new Error('Provide either onChange or onValueChange to Input component.')
    }

    const handleCamChange = (data: string) => {
        setToCrop(data)
        setShowCropperModel(true)
    }

    const handleClearImage = () => {
        setPreview(undefined)
        if(imageRef.current){
            imageRef.current.value = ''
        }
    }

    useEffect(() => {
        if (clearPreview) {
            setPreview(null)
            if(imageRef.current){
                imageRef.current.value = ''
            }
        }
    }, [clearPreview, imageRef])

    return (
        <Div className={'main-image-div'}>
            <Div className={'text-center'}>
                {(!preview && !loading) && <Div className={'option-image-div'}>
                    <Div onClick={openOsExplorer}><Icon className={'m-1 p-2 bg-light shadow image-container rounded float-left'} type={'solid'} icon={'fa-upload'} size={'4x'} /></Div>
                    {(camOff===undefined) && <Div onClick={()=>setShowCameraModel(true)}><Icon className={'m-1 p-2 bg-light shadow image-container rounded float-right d-none d-md-block'} type={'solid'} icon={'fa-camera'} size={'4x'} /></Div>}
                </Div>}
                {(preview  && !loading) && <Div className={'option-image-div text-center'}>
                    <Div onClick={handleClearImage}><Icon className={'m-1 p-2 bg-danger shadow image-container rounded'} type={'solid'} icon={'fa-trash'} size={'5x'}/></Div>
                </Div>}
                {loading && <Spinner variant={'grow'} color={'warning'}  size={'sm'} />}
                <Image
                    src={preview || previewThumb}
                    className={'img-fluid img-thumbnail'}
                    alt={thumb || 'image'}
                    width={width}
                    height={height}
                />
            </Div>
            <Input ref={imageRef} type={'file'} accept="image/png, image/jpeg" onChange={handleChange}/>
            {showCameraModel && <Camera
                showCameraModel={showCameraModel}
                setShowCameraModel={setShowCameraModel}
                setCameraThumb={setPreview}
                setFileToUpload={handleCamChange}
            />}
            {toCrop && <Cropper aspect={aspects} source={toCrop} showCropperModel={showCropperModel} setShowCropperModel={setShowCropperModel} setCroppedImage={handleCroppedChange}/>}
        </Div>
    )
}