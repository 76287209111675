import { useEffect, useState } from 'react'
import { ListBox, OwnerInformationModal } from '../../components'
import { titleCase } from '@bigbroindia/helper'
import { getColorByStatus } from '../../helpers/shorthand'
import { usePgStore, useUserStore } from '../../stores'
import { useNavigate } from 'react-router-dom'
import { NDataOptionModal } from '../../monorep'

const PgsList = () => {

    const navigate = useNavigate()

    const userStore = useUserStore()
    const pgStore = usePgStore()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [showOptionModal, setShowOptionModal] = useState<boolean>(false)
    const [showOwnerInformationModal, setShowOwnerInformationModal] = useState<boolean>(false)

    const handleRowClick = async (id: string) => {
        pgStore.getPg(id, () => {
            setShowOptionModal(true)
        })
    }

    useEffect(() => {
        const timeout = setTimeout(() => pgStore.getPgs())
        return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ListBox
          name={'PGs'}
          setCurrentPage={setCurrentPage}
          columns={{ name: 'Name', seater: 'Seater', rent: 'Rent', security: 'Security',city: 'City', views: 'Views' ,wishlists: 'Wishlisted By', status: 'Status' }}
          data={pgStore.pgs}
          meta={pgStore.meta}
          primary={['id']}
          handleRefreshButton={() => pgStore.getPgs()}
          onRowClick={(data: any) => handleRowClick(data.id)}
          handleAddButton={() => navigate('/property/pgs/add')}
        >
            {showOptionModal && <NDataOptionModal
                visible={showOptionModal}
                setVisible={setShowOptionModal}
                primary={pgStore.pg.id}
                options={
                    [
                        [
                            { value: titleCase(pgStore.pg.propertyStatus), color: getColorByStatus(pgStore.pg.propertyStatus), disabled: true }
                        ],
                        [
                            { title: pgStore.pg.propertyStatus === 'in-review' ? 'Review' : 'Update', color: 'info', icon: 'fa-pencil', onClick: (id: string) => pgStore.pg.propertyStatus === 'in-review' ? navigate('/property/pgs/:id/review'.replace(':id', id)) : navigate('/property/pgs/:id/update'.replace(':id', id)) },
                            {
                                title: 'View Owner Information', color: 'warning', icon: 'fa-eye', onClick: (id: string) => {
                                    userStore.getUser(pgStore.pg.ownerId, () => {
                                        setShowOwnerInformationModal(true)
                                        setShowOptionModal(false)
                                    })
                                }
                            }
                        ]
                    ]
                }
            />}
            {showOwnerInformationModal && <OwnerInformationModal
                showModal={showOwnerInformationModal}
                setShowModal={setShowOwnerInformationModal}
                user={userStore.user}
                setShowOptionModal={setShowOptionModal}
            />}
        </ListBox>
    )
}

export default PgsList