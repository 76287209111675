import { Card, CardBody, CardHeader, Col, Row } from "@nanobits/react-ui"
import { ImageUpload, NTextInput } from "../../monorep"
import { useAuthStore } from "../../stores"
import { formatDateTime } from "@bigbroindia/helper"

const ProfileNameCard = () => {

    const authStore = useAuthStore()

    return (
        <Card>
            <CardHeader className={'text-white bg-primary'}>{'Profile'}</CardHeader>
            <CardBody>
                <Row>
                    <Col md={12} sm={12}>
                        <Row>
                            <Col md={4} sm={12} >
                                <ImageUpload
                                    height={400}
                                    width={400}
                                />
                            </Col>
                            <Col md={8} sm={12}>
                                <Row>
                                    <Col className={'mb-3'} md={12} sm={12}>
                                        <NTextInput
                                            name={'name'}
                                            label={'Name'}
                                            value={authStore.profile.name || ''}
                                            disabled
                                        />
                                    </Col>
                                    <Col className={'mb-3'} md={12}>
                                        <NTextInput
                                            name={'mobile'}
                                            label={'Mobile'}
                                            value={authStore.profile.mobile || 'N/A'}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={'mb-3'} md={12}>
                                <NTextInput
                                    name={'email'}
                                    label={'Email'}
                                    value={authStore.profile.email || 'N/A'}
                                    disabled
                                />
                            </Col>
                            <Col md={12}>
                                <NTextInput
                                    name={'lastUpdate'}
                                    label={'Last Updated On'}
                                    value={formatDateTime(authStore.profile.updatedAt) || 'N/A'}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default ProfileNameCard