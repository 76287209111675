import { Heading } from '@nanobits/react-ui';
import Icon from '@nanobits/react-fa-icons';
import React from 'react'
import styled from 'styled-components';

interface FieldsetProps {
    children: React.ReactNode;
    label?: string;
    icon?: string;
    downBorder?: boolean
    className?: string
}

const FieldSetTag = styled.fieldset``


export const FieldSet = ({
    children,
    label,
    icon,
    downBorder,
    className
}: FieldsetProps) => {
    return (
        <FieldSetTag className={`form-group bg-white ${className} ${downBorder?'border-bottom border-2 border-primary':''}`}>
            <Heading className={'text-uppercase text-primary border-bottom border-2 border-primary mb-3'} size={4}>{icon && <Icon type={'solid'} icon={icon}/>} {label}</Heading>
            {children}
        </FieldSetTag>
    )
}