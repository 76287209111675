import { useEffect, useState } from "react"
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "@nanobits/react-ui"
import { NButton } from "@nanobits/react-components"
import { useFlatStore, useStateStore, useCityStore, useUserStore } from "../../stores"
import { FlatAPIPayload } from "../../models/flats/model"
import { useNavigate } from "react-router-dom"
import { getPropertyUpdateButtonByText } from "../../helpers/shorthand"
import { OwnerInformationInterface, PropertyFormInterface } from "../../@types"
import PropertyInformationFieldsComponent from "./components/Property"
import { BasicInformationFieldsComponent, LocationInformationFieldsComponent, AmenitiesInformationFieldsComponent, OwnerInformationFieldsComponent } from '../../components'

const FlatForm = ({
  formType = 'create'
}: PropertyFormInterface) => {

  const navigate = useNavigate()

  const stateStore = useStateStore()
  const cityStore = useCityStore()
  const flatStore = useFlatStore()
  const userStore = useUserStore()

  const [type, setType] = useState<string>()
  const [address, setAddress] = useState<string>()
  const [price, setPrice] = useState<number>()
  const [aop, setAop] = useState<number>()
  const [bedroom, setBedroom] = useState<number>()
  const [bathroom, setBathroom] = useState<number>()
  const [balcony, setBalcony] = useState<number>()
  const [floor, setFloor] = useState<number>()
  const [bhk, setBhk] = useState<number>()
  const [description, setDescription] = useState<string | undefined>()
  const [carpet_area, setCarpetArea] = useState<number>()
  const [project_name, setProjectName] = useState<string>()
  const [ownerId, setOwnerId] = useState<string>()
  const [ownerInformation, setOwnerInformation] = useState<OwnerInformationInterface>({} as OwnerInformationInterface)


  const [state_id, setStateId] = useState<number>()
  const [city_id, setCityId] = useState<number>()

  const [furnishing, setFurnishing] = useState<'unfurnished' | 'semi-furnished' | 'furnished'>('unfurnished')
  const [bachelor_friendly, setBachelorFriendely] = useState<boolean>(false)
  const [parking, setParking] = useState<boolean>(false)

  const submitAction = () => navigate('/property/flats')

  const handleSubmit = async () => {
    const postData: FlatAPIPayload = {
      city_id: Number(city_id),
      address: String(address),
      price: Number(price),
      furnishing,
      parking,
      aop: Number(aop),
      bedroom: Number(bedroom),
      bathroom: Number(bathroom),
      balcony: Number(balcony),
      floor: Number(floor),
      bhk: Number(bhk),
      description: String(description),
      carpet_area: Number(carpet_area),
      project_name: String(project_name),
      type: String(type),
      bachelor_friendly
    }
    if (formType === 'create') {
      flatStore.postFlat({...postData, owner_id: ownerId}, submitAction)
    } else {
      flatStore.putFlat(flatStore.flat.id, postData, submitAction)
    }
  }

  const handleReviewAction = (action: 'approved' | 'rejected') => {
    flatStore.reviewFlat(String(flatStore.flat.id), { status: action === 'approved' ? null : 'rejected' }, submitAction)
  }

  useEffect(() => {
    if ((formType === 'update' || formType === 'review') && flatStore.flat.id === undefined) {
      navigate('/property/flats')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatStore.flat.id, formType])

  useEffect(() => {
    const timeout = setTimeout(() => stateStore.getStatesDropdown({ country_id: 1 }))
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formType === 'update' || formType === 'review') {
      cityStore.getCitiesDropdown({ state_id: state_id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state_id, formType])


  useEffect(() => {
    if ((formType === 'update' || formType === 'review') && flatStore.flat.id !== undefined) {
      setFurnishing(flatStore.flat.furnishing)
      setBachelorFriendely(flatStore.flat.bachelorFriendly)
      setParking(flatStore.flat.parking)
      setType(flatStore.flat.type)
      setStateId(flatStore.flat.city.stateId)
      setCityId(flatStore.flat.cityId)
      setAddress(flatStore.flat.address)
      setProjectName(flatStore.flat.projectName)
      setAop(Number(flatStore.flat.aop))
      setPrice(Number(flatStore.flat.price))
      setBathroom(flatStore.flat.bathroom)
      setBedroom(flatStore.flat.bedroom)
      setBalcony(flatStore.flat.balcony)
      setFloor(flatStore.flat.floor)
      setBhk(flatStore.flat.bhk)
      setCarpetArea(Number(flatStore.flat.carpetArea))
      setDescription(String(flatStore.flat.description))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatStore, formType])

  useEffect(() => {
    if (formType === 'review' && flatStore.flat.id !== undefined && flatStore.flat.ownerId !== flatStore.flat.registeredBy) {
      userStore.getUser(flatStore.flat.ownerId, () => {
        setOwnerInformation({ id: userStore.user.id, name: userStore.user.name, email: userStore.user?.email, mobile: userStore.user?.mobile })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatStore, formType])

  useEffect(() => {
    if (formType === 'create') {
      if (ownerId === userStore.owner.email) {
        setOwnerInformation(userStore.owner)
      } else {
        setOwnerInformation({} as OwnerInformationInterface)
      }
    }
  }, [formType, ownerId, userStore.owner])

  return (
    <>
      <Row>
        <Col md={12}>
          <Card
          >
            <CardHeader className={'bg-primary text-white'} >
              {`${(formType === 'create' && 'Create') || (formType === 'update' && 'Update') || (formType === 'review' && 'Review')} ${formType === 'create' ? 'Flat' : `Flat : ${flatStore.flat.projectName}`}`}&nbsp;
              <NButton
                className={'float-end mx-1 btn-success text-white'}
                size={'sm'}
                buttonIconLeft={'fa fa-bars'}
                onClick={() => navigate('/property/flats')}
                loading={flatStore.loading}
              />
            </CardHeader>
            <CardBody>
              <BasicInformationFieldsComponent
                formType={formType}
                type={type}
                setType={setType}
              />
              <LocationInformationFieldsComponent
                formType={formType}
                state_id={state_id}
                setStateId={setStateId}
                city_id={city_id}
                setCityId={setCityId}
                address={address}
                setAddress={setAddress}
              />
              <PropertyInformationFieldsComponent
                formType={formType}
                projectName={project_name}
                setProjectName={setProjectName}
                aop={aop}
                setAop={setAop}
                price={price}
                setPrice={setPrice}
                bedroom={bedroom}
                setBedroom={setBedroom}
                bathroom={bathroom}
                setBathroom={setBathroom}
                balcony={balcony}
                setBalcony={setBalcony}
                floor={floor}
                setFloor={setFloor}
                bhk={bhk}
                setBhk={setBhk}
                carpetArea={carpet_area}
                setCarpetArea={setCarpetArea}
                description={description}
                setDescription={setDescription}
              />
              <AmenitiesInformationFieldsComponent
                formType={formType}
                bachelorFriendly={bachelor_friendly}
                setBachelorFriendly={setBachelorFriendely}
                furnishing={furnishing}
                setFurnishing={setFurnishing}
                parking={parking}
                setParking={setParking}
              />
              <OwnerInformationFieldsComponent
                formType={formType}
                ownerInformation={ownerInformation}
                userInformation={flatStore.flat.user}
                ownerId={flatStore.flat.ownerId}
                registeredBy={flatStore.flat.registeredBy}
                setOwnerId={setOwnerId}
              />
            </CardBody>
            <CardFooter>
              <NButton
                className={'text-white px-4 mx-1 fw-bold float-end'}
                color={'success'}
                buttonText={getPropertyUpdateButtonByText(formType)}
                loading={flatStore.loading}
                onClick={formType === 'review' ? () => handleReviewAction('approved') : handleSubmit}
                disabled={formType === 'create' && ownerInformation.id === undefined}
              />
              {formType === 'review' && <NButton
                className={'text-white px-4 fw-bold float-end'}
                color={'danger'}
                buttonText={'Reject'}
                loading={flatStore.loading}
                onClick={() => handleReviewAction('rejected')}
              />}
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FlatForm